<template>
    <div class="page">
        <div class="ctn">
            <div class="left">
                <div class="unit bg-img" :style="{'background-image':'url('+data.main.img+')'}">
                    <div class="wrapper">
                        <h1 class="fs30">{{data.main.title}}</h1>
                        <p class="fs20">{{data.main.subTitle}}</p>
                        <p class="main-txt">{{data.main.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="unit bg-img" v-for="(item,i) in data.minor" :key="i" 
                    :style="{'background-image':'url('+item.img+')'}">
                    <div :class="['wrapper',activeIdx==i?'active':'']" @mouseenter="activeIdx=i">
                        <div :class="['txt',activeIdx!=i?'active':'']">
                            <h1 class="fs20">{{item.title}}</h1>
                            <p class="fs16">{{item.subTitle}}</p>
                            <p v-if="activeIdx==i" class="main-txt">{{item.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"],
    data(){
        return {
            activeIdx:0,
        }
    }   
}
</script>
<style scoped lang="less">

.page{
    margin-top:100px;
    .ctn{
        width:1300px;
        margin:0 auto;
        display: flex;
    }
    .unit{
        position: relative;
        .wrapper{
            position:absolute; 
            
        }
    }
    .left{
        width:550px;
        height:550px;
        margin-right:10px;
        .unit{
            width:100%;
            height:100%;
            .wrapper{
                bottom:50px;
                left:50px;   
                .fs20{
                    margin:5px 0 20px 0;
                }
            }
        }
    }
    .right{
        flex:1;
        height:300px;
        display: flex;
        flex-wrap: wrap;
        
        .unit{
            width:365px;
            height:270px;
            .wrapper{
                cursor: pointer;
                height:230px;
                width:325px;
                top:0;
                left:0;
                padding:20px; 
                /*background:-webkit-linear-gradient(right,rgba(255,255,255,0),rgba(255,255,255,0.5));*/
                .fs16{
                    margin:5px 0 0 0;
                    
                }
                .main-txt{
                    margin-top:20px;
                    width:60%;
                    color:#333;
                }
                .txt.active{
                    
                    *{
                        text-shadow: 1px 1px white;
                    }
                }
            }
            .wrapper.active{
                background:#f9f9f9;
            }
        }
        .unit:nth-of-type(1){
            margin-bottom:10px;
        }
        .unit:nth-of-type(2){
            margin-left:10px;
            margin-bottom:10px;
        }
        .unit:nth-of-type(4){
            margin-left:10px;
        }
    }
}
</style>