<template>
    <div class="menu">
        <div class="ctn" ref="ctn">
            <div class="bar">
                <p class="logo" @click="toHome">
                    <Icons name="logo" height="20" width="20" :color="iconColor"/>
                    <span class="name">DeepBlue</span>
                </p>
                <p class="cat" @click="showMenu" v-if="!showList"><Icons name="grid" height="20" width="20" :color="iconColor"/></p>
                <p class="cat" @click="closeMenu" v-else><Icons name="close" height="20" width="20" :color="iconColor"/></p>
            </div>
            <div class="list" v-show="showList">
                <div class="unit" v-for="(item,i) in data" :key="i" @click="redirect(item.path)" @mouseenter="hover(i)">
                    <p class="line">
                        <span class="fs14">{{item.title}}</span><small class="fs12">{{item.subTitle}}</small>
                    </p>
                    <p class="line">
                        <span class="fs12 gray" v-for="(child,j) in item.children" :key="j">{{child}}</span>
                    </p>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import $ from 'jquery'
import Icons from '@/components/common/Icons.vue'
export default {
    components:{
        Icons
    },
    props:["data"],
    data(){
        return {
            showList:false,
            itv:null,
            ctn:null,
            iconColor:'white'
        }
    },
    mounted(){
        //this.toggleMenuVisible();
        this.ctn=this.$refs.ctn;
    },
    methods:{
        hover(i){
            $(this.ctn).find('.unit').css({top:0,border:'none'});
            $(this.ctn).find('.unit').eq(i).css({top:'-2px','border-bottom':'1px solid #999'});
        },
        toHome(){
            this.$router.push('/');
        },
        redirect(path){
            if(path!=this.$route.path){
                this.closeMenu();
                this.$router.push(path);
            };
        },
        closeMenu(){
            $(this.ctn).css({position:'absolute'});
            $(this.ctn).find(".bar").css({background:'none'}).find('p').css({color:'white'});
            this.iconColor='white';
            this.showList=false;
        },
        showMenu(){
            $(this.ctn).css({position:'fixed'});
            $(this.ctn).find(".bar").css({background:'white'}).find('p').css({color:'#333'});
            this.iconColor='#333';
            this.showList=true;
        },
        toggleMenuVisible(){
            let headH=$(".head").height();
            let scrollTop=0;
            this.itv=setInterval(() => {
                scrollTop=$(document).scrollTop();
                if(scrollTop>headH/2){
                    $(this.$refs.menu).fadeOut();
                }else{
                    $(this.$refs.menu).fadeIn();
                }
            }, 500);
            
        },
        toggleMenu(){
            let headH=$(".head").height();
            let scrollTop=0;
            this.itv=setInterval(() => {
                scrollTop=$(document).scrollTop();
                if(scrollTop>headH/2){
                    $(".menu").css({background:'black'}).find('p').css({color:'white'});
                }else{
                    $(".menu").css({background:'none'}).find('p').css({color:'#333'});
                }
            }, 500);
            
        }
    },
    beforeDestroy(){
        clearInterval(this.itv);
    }
}
</script>
<style scoped lang="less">

.ctn{
    width:100%;
    position: absolute;
    top:0;
    left:0;
    z-index: 5;
    
    .bar{
        padding:15px 2% 0 2%;
        font-size:12px;
        display: flex;
        justify-content: space-between;
        .logo{
            .name{
                padding-left:5px;
                position: relative;
                top:-5px;
                color:white;
            }
        }
        p{
            color:white;
            cursor: pointer;
        }
    }
    /*background:-webkit-linear-gradient(bottom,rgba(255,255,255,0),rgba(255,255,255,0.5))*/
    .list{
        background:white;
        padding:0 2%;
        display: flex;
        justify-content: space-between;
        .unit{
            padding:15px 0 10px 0;
            cursor: pointer;
            position: relative;
        }
        p{
            margin-bottom:5px;
            small{
                padding-left:10px;
            }
            .fs12{
                padding-right:5px;
            }
        }
        
    }
}
</style>