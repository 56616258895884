<template>
    <div class="head bg-img" :style="{'background-image':'url('+data.img+')'}">
        <div class="content">
            <h1 class="fs45">{{data.title}}</h1>
            <p class="fs30">{{data.subTitle}}</p>
            <div class="service">
                <div :class="['ctn',i==activeIdx?'active':'']" 
                    v-for="(item,i) in data.items" :key="i"
                    @mouseover="toggle(i)">
                    <dl>
                        <dd class="icon"><Icons :name="item.icon" height="25" width="25" color="#0071e3"/></dd>
                        <dd class="name fs16">{{item.title}}</dd>
                        <dd class="desc fs12">{{item.subTitle}}</dd>
                    </dl>
                    <div class="cover"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {EventBus} from '@/bus/index.js'
import Icons from '@/components/common/Icons.vue'
export default {
    components:{
        Icons
    },
    props:["data"],
    data(){
        return {
            activeIdx:0,
        }
    },
    methods:{
        toggle(i){
            this.activeIdx=i;
            EventBus.$emit('headToggle',i);
        },
    }
}
</script>
<style scoped lang="less">
.head{
    .content{
        padding:50px 0;
        h1{
            text-align: center;
            color:white;
        }
        >p{
            text-align: center;
            margin:5px 0 50px 0;
            color:white;
        }
        .service{
            display: flex;
            justify-content: center;
            .ctn{
                width:150px;
                position:relative;
                margin:0px 1px;
                overflow: hidden;
                cursor: pointer;
                .cover{
                    width:100%;
                    height:100%;
                    position:absolute;
                    top:0;
                    left:0;
                    background:rgb(255,255,255);
                    opacity:0.7;
                }
            }
            .ctn.active{
                .cover{
                    opacity:1;
                }
            }
            dl{
                width:100%;
                padding:20px 0;
                text-align: center;
                position: relative;
                z-index: 2;
                .icon{
                    img{
                        width:30px;
                        height:30px;
                    }
                }
                .name{
                    margin:10px 0;
                }
                
            }
        }

    }
}
</style>