<template>
    <div class="tech">
        <div class="ctn">
            <dl v-for="(item,i) in data" :key="i">
                <dt :style="{'background-image':'url('+item.img.path+')'}" v-if="item.img.direct=='left'" class="img-left"></dt>
                <dd :class="item.img.direct=='left'?'txt-left':'txt-right'">
                    <h3>{{item.text.title}}</h3>
                    <h4>{{item.text.subTitle}}</h4>
                    <p v-for="(txt,j) in item.text.desc" :key="j">{{txt}}</p>
                </dd>
                <dt :style="{'background-image':'url('+item.img.path+')'}" v-if="item.img.direct=='right'" class="img-right"></dt>
            </dl>
        </div>
    </div>
</template>
<script>

export default {
    props:["data"],
    data(){
        return {
            
        }
    }
}
</script>
<style scoped lang="less">
.tech{
    margin-top:100px;
    .ctn{
        margin:0 auto;
        width:1100px;
        height:550px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        dl{
            width:50%;
            display: flex;
            dt{
               width:265px;
               height:265px; 
               background-repeat: no-repeat;
               background-position: center;
               background-size: cover;
               
            }
            .img-left{
                margin-right:10px;
            }
            .img-right{
                margin-left:10px;
            }
            dd{
                width:185px;
                height:185px;
                background:#f8f8f8;
                padding:40px;
                h3{
                    font-size:18px;
                    margin-bottom:5px;
                }
                h4{
                    font-size:14px;
                    margin-bottom:50px;
                }
                p{
                    margin-top:5px;
                }
            }
            .txt-right{
                text-align: right;
            }
        }
    }
}
</style>