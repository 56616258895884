<template>
    <div class="slogan">
        <div class="left">
            <h1 class="fs45" v-html="activeItem.title"></h1>
            <h2 class="fs30"  v-html="activeItem.subTitle"></h2>
            <p><a class="blue-btn" href="tencent://message/?uin=1771548541" target="_blank">在线咨询</a></p>
        </div>
        <div class="right">
            <p class="main-txt-pc" v-html="activeItem.desc"></p>
        </div>
    </div>
</template>
<script>
import {EventBus} from '@/bus/index.js'
export default {
    props:['data',],
    data(){
        return {
            activeIdx:0,
        }
    },
    created(){
        this.setActiveItem(0);
    },
    watch:{
        
    },
    mounted(){
        EventBus.$on('headToggle',(idx)=>{
            if(idx!==this.activeIdx){
                this.activeIdx=idx;
                this.setActiveItem(idx);
                this.$forceUpdate();
            }
        })
    },
    methods:{
        setActiveItem(idx){
            if(this.data instanceof Array){
                this.activeItem=this.data[idx];
            }else{
                this.activeItem=this.data;
            }

        }
    }

}
</script>
<style scoped lang="less">
.slogan{
    width:80%;
    margin-top:100px;
    padding:0 10%;
    display: flex;
    .left{
        width:50%;
        h2{
            margin:20px 0 30px 0;
        }

    }
    .right{
        flex:1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>