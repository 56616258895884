<template>
    <div class="slide">
        <div class="page">
            <div :class="['image', 'bg-img',activeIdx==i?'active':'']" v-for="(item,i) in fourItems" :key="i" 
                :style="{'background-image':'url('+item.img+')'}" @mouseenter="setAminate(i)"></div>
            <div class="text">
                <div class="ctn">
                    <p class="tag fs12 gray">{{data[activeIdx].tag}}</p>
                    <h1 class="fs20">{{data[activeIdx].title}}</h1>
                    <p class="desc main-txt">{{data[activeIdx].desc}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    props:["data"],
    data(){
        return {
            activeIdx:0,
        }
    },
    computed:{
        fourItems(){
            return this.data.filter((val,idx)=>idx<4);
        }
    },
    mounted(){
        this.setPosition();
    },
    methods:{
        setPosition(){
            let imgW=$(".image").eq(0).width();
            let ctnW=imgW+10;
            let txtW=$(window).width()-(ctnW*$(".image").length);
            let vm=this;
            $(".image").each(function(idx){
                let left=idx*ctnW;
                if(vm.activeIdx==idx){
                    
                    let txtLeft=left+ctnW;
                    $(".text").css({"width":txtW+'px',"left":txtLeft+'px'});
                    
                }
                if(idx>vm.activeIdx){
                    $(this).css("left",(left+txtW+10)+'px');
                }else{
                    $(this).css("left",left+'px');
                }
                
            });
        },
        setAminate(idx){
          this.activeIdx=idx;
          this.setPosition();
        }
    }
}
</script>
<style scoped lang="less">
.slide{
    margin-top:100px;
    .page{
        position: relative;
        width:100%;
        height:300px;
        .image{
            width:17%;
            height:300px;
            position: absolute;
            top:0;
            left:0;
            cursor: pointer;
        }
        .image.active{
            box-shadow: 0 0 4px 2px #d8d8d8;
        }
        .text{
            position: absolute;
            top:0;
            left:0;
            height:200px;
            padding:50px 0;
            background:#f8f8f8;
            .ctn{
                padding:0 50px;
            }
            h1{
                font-size:20px;
                margin:5px 0 50px 0;
            }
        }
    }
}
</style>