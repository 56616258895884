<template>
    <div class="foot">
        <div class="brand">
            <h1 class="fs30 blue">{{footData.brand.title}}</h1>
            <p class="fs20">{{footData.brand.subTitle}}</p>
            <p class="fs14 gray">统一社会信用代码: {{footData.brand.code}}</p>
            <p class="main-txt" v-html="footData.brand.desc"></p>
        </div>
        <div class="items">
            <div class="unit" v-for="(item,i) in footData.items" :key="i">
                <p class="fs16">{{item.title}}</p>
                <p class="fs12">{{item.subTitle}}</p>
                <dl>
                    <dd v-for="(option,j) in item.options" :key="j" class="link" @click="redirect(option)">
                        <p class="fs14">{{option.title}} <small class="fs12 gray">{{option.subTitle}}</small></p>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>

import footData from '@/data/footer.js'
export default {
    data(){
        return {
            footData,
        }
    },
    methods:{
        redirect(option){
            if(option.newTab){
                window.open(option.path,'outside');
            }else{
                this.$router.push(option.path);
            }
        }
    }
}
</script>
<style scoped lang="less">
.foot{
    padding:50px 10%;
    width:80%;
    background:#f9f9f9;
    display: flex;
    justify-content: space-between;
    
    .brand{
        width:50%;
        .fs20{
            margin-top:10px;
        }
        .fs14{
            margin:10px 0 50px 0;
        }
        .main-txt{
            width:500px;
        }
    }
    .items{
        margin-top:155px;
        flex:1;
        display: flex;
        justify-content: space-between;
        .unit{
            p.fs12{
                margin:5px 0 30px 0;
            }
            dl{
                dd{
                    margin-bottom:5px;
                }
            }
            .link:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
</style>