<template>
    <div class="service">
        <Menu :data="homeData.service"/>
        
        <Head :data="pageData.head"/>
        <Slogan :data="pageData.slogan"/>
        <Slide :data="pageData.slide"/>

        <ModuleHead :data="pageData.page.header"/>
        <Page :data="pageData.page.items"/>

        <ModuleHead :data="pageData.tech.header"/>
        <Tech :data="pageData.tech.items"/>
        <Fee :data="pageData.fee"/>
        <Foot/>
    </div>
</template>
<script>
import Head from '@/components/service/Head/Pc.vue'
import Page from '@/components/service/Page/Pc.vue'
import Tech from '@/components/service/Tech/Pc.vue'
import Fee from '@/components/service/Fee/Pc.vue'
import Slide from '@/components/service/Slide/Pc.vue'

import Foot from '@/components/common/Foot/Pc.vue'
import ModuleHead from '@/components/common/ModuleHead/Pc.vue'
import Slogan from '@/components/common/Slogan/Pc.vue'
import Menu from '@/components/common/Menu/Pc.vue'

import homeData from '@/data/home.js'
import serviceData from '@/data/service.js'
export default {
    components:{
        Head,Page,Tech,Fee,Slide,
        Foot,ModuleHead,Slogan,Menu
    },
    computed:{
        pageData(){
            return serviceData[this.$route.params.page];
        }
    },
    data(){
        return {
            homeData
        }
    }
}
</script>
<style scoped lang="less">
.service{

}
</style>