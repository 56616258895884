<template>
    <div class="fee bg-img-bot" :style="{'background-image':'url('+data.img+')'}">
        <div class="ctn">
            <h1 class="fs30">{{data.title}}</h1>
            <h2 class="fs20">{{data.subTitle}}</h2>
            <p class="right"><a class="blue-btn" href="tencent://message/?uin=1771548541" target="_blank">询价</a></p>
            <div class="content">
                <div class="unit" v-for="(item,i) in data.items" :key="i">
                    <p class="tag">{{item.tag}}</p>
                    <p class="title">{{item.title}}</p>
                    <p class="main-txt desc" v-html="item.desc"></p>
                    <div class="price">
                        <p class="left"><span>￥</span><span class="num">{{item.price}}</span><span class="">{{item.unit}}</span></p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:["data"],
    data(){
        return {
            
        }
    }
}
</script>
<style scoped lang="less">
.fee{
    margin-top:100px;
    .ctn{
        width:1000px;
        padding:50px 0;
        margin:0 auto;
        h1{
            font-size:30px;
            
        }
        h2{
            font-size:20px;
            margin:10px 0 20px 0;
        }
        .content{
            display: flex;
            justify-content: space-between;
            margin-top:50px;
            .unit{
                width:300px;
                background:rgba(255,255,255,0.9);
                padding:50px;
                .tag{
                    font-size:12px;
                    color:gray;
                }
                .title{
                    font-size:16px;
                    margin:10px 0 0 0;
                    font-weight: bold;
                }
                .desc{
                    margin:40px 0;
                    
                }
                .price{
                    
                    display:flex;
                    justify-content: space-between;
                    .num{
                        font-size:20px;
                        font-weight: bold;
                        padding-right:10px;
                    }
                    .btn{
                        
                        input{
                            width:50px;
                            height:30px;
                            background:gray;
                            border:none;
                            color:white;
                        }
                    }
                }
            }
        }
    }
}
</style>