<template>
    <div class="module-head" :style="{'background-color':bg?bg:'inherit'}">
        
        <h1 class="fs30">{{title}}</h1>
        <h2 class="fs20">{{subTitle}}</h2>
        <p class="main-txt" v-html="desc"></p>
    </div>
</template>
<script>
export default {
    props:["data","bg"],
    data(){
        return {
            dft:{
                title:"title",
                subTitle:"sub title",
                desc:"description"
            }
        }
    },
    computed:{
        title(){
            return this.data && this.data.title || this.dft.title;
        },
        subTitle(){
            return this.data && this.data.subTitle || this.dft.subTitle;
        },
        desc(){
            return this.data && this.data.desc || this.dft.desc;
        }
    }
}
</script>
<style scoped lang="less">
.module-head{
    
    width:80%;
    padding:0 10%;
    padding-top:100px;
    text-align: center;
    h2{
        font-weight: normal;
        margin:10px 0 30px 0;
    }
}
</style>